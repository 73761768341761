import * as React from "react";
import { Link } from "gatsby";

import Seo from "../components/Seo";

import { Container, H1, Button } from "../components/Design";

// markup
const NotFoundPage = () => {
  return (
    <Container style={{ marginTop: "60px" }}>
      <Seo />
      <H1>404</H1>
      <Link to="/">
        <Button>Zurück zur Startseite</Button>
      </Link>
    </Container>
  );
};

export default NotFoundPage;
